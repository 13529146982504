import { css } from 'styled-components'
import { math } from 'polished'

import { media } from 'styles/media'

export default css`
  .u-1-1 {
    width: ${math('1/1') * '100' + '%'} !important;
  }

  .u-1-4 {
    width: ${math('1/4') * '100' + '%'} !important;
  }

  .u-1-3 {
    width: ${math('1/3') * '100' + '%'} !important;
  }

  .u-1-2 {
    width: ${math('1/2') * '100' + '%'} !important;
  }

  ${media.small`

    .u-1-2-sm {
      width: ${math('1/2') * '100' + '%'} !important;
    }

    .u-1-3-sm {
      width: ${math('1/3') * '100' + '%'} !important;
    }

    .u-2-3-sm {
      width: ${math('2/3') * '100' + '%'} !important;
    }

    .u-3-4-sm {
      width: ${math('3/4') * '100' + '%'} !important;
    }

    .u-1-4-sm {
      width: ${math('1/4') * '100' + '%'} !important;
    }

  `};

  ${media.medium`

    .u-1-1-md {
      width: 100% !important;
    }

    .u-1-2-md {
      width: ${math('1/2') * '100' + '%'} !important;
    }

    .u-1-3-md {
      width: ${math('1/3') * '100' + '%'} !important;
    }

    .u-1-4-md {
      width: ${math('1/4') * '100' + '%'} !important;
    }

    .u-1-5-md {
      width: ${math('1/5') * '100' + '%'} !important;
    }

    .u-2-3-md {
      width: ${math('2/3') * '100' + '%'} !important;
    }

    .u-2-5-md {
      width: ${math('2/5') * '100' + '%'} !important;
    }

    .u-3-5-md {
      width: ${math('3/5') * '100' + '%'} !important;
    }

  `};

  ${media.large`

    .u-1-2-lg {
      width: ${math('1/2') * '100' + '%'} !important;
    }

    .u-1-3-lg {
      width: ${math('1/3') * '100' + '%'} !important;
    }

    .u-1-6-lg {
      width: ${math('1/6') * '100' + '%'} !important;
    }

    .u-5-12-lg {
      width: ${math('5/12') * '100' + '%'} !important;
    }

    .u-7-12-lg {
      width: ${math('7/12') * '100' + '%'} !important;
    }

  `};

  ${media.xLarge`

    .u-1-5-lg {
      width: ${math('1/5') * '100' + '%'} !important;
    }

    .u-1-6-xlg {
      width: ${math('1/6') * '100' + '%'} !important;
    }

    .u-2-5-lg {
      width: ${math('2/5') * '100' + '%'} !important;
    }

  `};
`
