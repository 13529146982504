import React from 'react'
import { node, object } from 'prop-types'

import { GlobalStyle } from 'styles/global'

import Footer from 'components/Footer'
import Nav from 'components/Nav'
import Provinces from 'components/Provinces'

import 'i18n'

const Layout = ({ location, children }) => (
  <>
    <GlobalStyle />
    <Nav location={location} />
    <div
      css={`
        background-color: #353f46;
      `}
    >
      {children}
    </div>
    <Provinces location={location} />
    <Footer />
  </>
)

Layout.propTypes = {
  children: node.isRequired,
  location: object,
}

export default Layout
