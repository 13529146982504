import React from 'react'
import styled from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { stripUnit } from 'polished'

import { teal, white } from 'styles/colors'
import { media } from 'styles/media'
import { verticalPadding } from 'styles/helpers'
import { fontWeights } from 'styles/typography'
import * as spacing from 'styles/spacing'

import Wrapper from 'components/Wrapper'

const Container = styled.footer`
  background-color: ${teal};
  text-align: center;
  display: block;
  color: ${white};
  font-weight: ${fontWeights.semibold};
  ${verticalPadding`32px`};

  a {
    color: inherit;
    text-decoration: underline;
  }

  ${media.small`
    ${verticalPadding`40px`};
  `};

  ${media.medium`
    ${verticalPadding`56px`};
  `};

  ${media.large`
    ${verticalPadding`64px`};
  `};

  ${media.xLarge`
    ${verticalPadding`80px`};
  `};
`

const Inner = styled.div`
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;

  ${media.medium`
    max-width: 840px;
  `};
`

const StyledLogo = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: ${spacing.large};
  width: 120px;
  height: auto;

  ${media.small`
    width: 140px;
  `};

  ${media.medium`
    margin-bottom: ${spacing.xLarge};
  `};

  ${media.large`
    width: 160px;
  `};

  ${media.xLarge`
    margin-bottom: ${spacing.xxLarge};
    width: 180px;
  `};
`

const FactsLogo = styled.img`
  height: 14px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: ${spacing.small};

  ${media.medium`
    height: 15px;
    margin-top: ${stripUnit(spacing.small) * 1.5 + 'px'};
  `};

  ${media.large`
    height: 17px;
  `};
`

const Footer = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Wrapper>
        <Inner>
          <StyledLogo src={require(`assets/images/logo.png`)} />
          <p>
            {t('footer.factsNotFear.text')}{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={t('footer.factsNotFear.link')}
            >
              <FactsLogo
                src={require(`assets/images/facts-not-fear-logo-${process.env.GATSBY_LOCALE}.png`)}
                alt="Facts Not Fear logo"
              />
            </a>
          </p>
          <p>
            <Trans t={t} i18nKey="footer.vuse">
              <span>
                For more information on Imperial Tobacco Canada’s vaping
                products, please{' '}
              </span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.vuse.com/ca/en"
              >
                click here
              </a>
            </Trans>
          </p>
        </Inner>
      </Wrapper>
    </Container>
  )
}

export default Footer
