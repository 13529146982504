import React from 'react'
import { node, bool } from 'prop-types'
import styled, { css } from 'styled-components'

import * as spacing from 'styles/spacing'
import { media } from 'styles/media'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1320px;
  padding-left: ${spacing.large};
  padding-right: ${spacing.large};

  ${props =>
    props.narrow &&
    css`
      max-width: 960px;
    `}

  ${media.small`
    padding-left: 5%;
    padding-right: 5%;
  `};
`

const Wrapper = ({ children, narrow }) => (
  <Container narrow={narrow}>{children}</Container>
)

Wrapper.propTypes = {
  children: node.isRequired,
  narrow: bool,
}

export default Wrapper
