import styled, { css } from 'styled-components'
import { stripUnit } from 'polished'

import { media } from './media'
import { primaryColor, grey } from './colors'

// Font family
export const fontFamily =
  "montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"

// Font weights
export const fontWeights = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
}

export const lineHeights = {
  base: 1.5,
  heading: 1.25,
  tight: 1.05,
}

// Font sizes
export const fontSizes = {
  regular: '16px',
  small: '14px',
  tiny: '12px',
}

export const Lead = styled.p`
  font-size: 1.1em;

  ${media.medium`
    font-size: 1.2em;
  `};
`

export default css`
  body {
    font-family: ${fontFamily};
    font-weight: ${fontWeights.regular};
    line-height: ${lineHeights.base};
    color: ${grey};
    font-size: ${fontSizes.regular};

    ${media.medium`
      font-size: ${stripUnit(fontSizes.regular) + 1 + 'px'};
    `};

    ${media.xLarge`
      font-size: ${stripUnit(fontSizes.regular) + 2 + 'px'};
    `};
  }

  small {
    font-size: ${fontSizes.small};
  }

  strong {
    font-weight: ${fontWeights.bold};
  }

  p {
    margin-bottom: 1.25em;

    &:last-child {
      margin-bottom: 0;
    }

    ${media.medium`
      margin-bottom: 1.5em;
    `};
  }

  p a {
    color: ${primaryColor};
    font-weight: ${fontWeights.bold};
  }
`
