import React from 'react'
import { number } from 'prop-types'
import styled, { css } from 'styled-components'

import { fontWeights, lineHeights } from 'styles/typography'
import { black } from 'styles/colors'
import { media } from 'styles/media'

const getFontSize = size => {
  if (size === 1)
    return css`
      font-size: 32px;
      line-height: 1;

      ${media.small`
        font-size: 40px;
      `};

      ${media.medium`
        font-size: 48px;
      `};

      ${media.large`
        font-size: 56px;
      `};

      ${media.xLarge`
        font-size: 64px;
      `};
    `

  if (size === 2)
    return css`
      font-size: 24px;
      line-height: 1.25;

      ${media.small`
        font-size: 28px;
      `};

      ${media.medium`
        font-size: 32px;
      `};

      ${media.large`
        font-size: 36px;
      `};

      ${media.xLarge`
        font-size: 40px;
      `};
    `

  if (size === 3)
    return css`
      font-size: 18px;
      line-height: 1.25;

      ${media.small`
        font-size: 20px;
      `};

      ${media.medium`
        font-size: 22px;
      `};

      ${media.large`
        font-size: 24px;
      `};
    `
}

const Container = styled.div`
  font-weight: ${fontWeights.bold};
  line-height: ${lineHeights.heading};
  color: ${black};
  margin-bottom: 0.25em;
  ${props => getFontSize(props.size)};

  &:last-child {
    margin-bottom: 0;
  }
`

const Heading = ({ size = '5', ...props }) => (
  <Container as={`h${size}`} size={size} {...props} />
)

Heading.propTypes = {
  size: number,
}

export default Heading
