import React from 'react'
import styled from 'styled-components'
import { string } from 'prop-types'

import { square } from 'styles/helpers'

const getSize = ({ size }) => {
  if (size === 'tiny') return '12px'
  if (size === 'small') return '16px'
  if (size === 'large') return '32px'
  return '24px'
}

class Icon extends React.Component {
  state = {
    IconComponent: null,
  }

  async componentDidMount() {
    const { default: IconComponent } = await import(
      `assets/icons/${this.props.icon}.svg`
    )
    this.setState({ IconComponent })
  }

  render() {
    const { icon, ...rest } = this.props
    const { IconComponent } = this.state

    if (!IconComponent) return null

    const Container = styled(({ icon, size, ...rest }) => (
      <IconComponent {...rest} />
    ))`
      display: inline-block;
      vertical-align: top;
      fill: currentColor;
      ${square(getSize)};
    `

    return <Container {...rest} />
  }
}

Icon.propTypes = {
  icon: string.isRequired,
  size: string,
}

export default Icon
