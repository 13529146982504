export const pathLangMap = {
  fr: {
    '/bc/': '/cb/',
    '/nova-scotia/': '/nouvelle-ecosse/',
    '/prince-edward-island/': '/ile-du-prince-edouard/',
    '/ontario/': '/ontario/',
    '/saskatchewan/': '/saskatchewan/',
    '/alberta/': '/alberta/',
    '/newfoundland-and-labrador/': '/terre-neuve-et-labrador/',
    '/quebec/': '/quebec/',
    '/new-brunswick/': '/nouveau-brunswick/',
    '/manitoba/': '/manitoba/',
  },
}

export const domainLangMap = {
  en: 'https://myvaperegs.ca',
  fr: 'https://normesdevapotage.ca',
}
