import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { media } from 'styles/media'
import { fontWeights } from 'styles/typography'
import { white } from 'styles/colors'
import {
  navHeight,
  navHeightMobile,
  animationTime,
  animationCurve,
} from 'styles/variables'

import Wrapper from 'components/Wrapper'
import MobileNav from 'components/MobileNav'
import Link from 'components/LocalizedLink'
import NavItem from './NavItem'
import LangSwitcher from './LangSwitcher'

const currentLang = process.env.GATSBY_LOCALE

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  transition: background-color ${animationTime} ${animationCurve};
  z-index: 400;
`

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${navHeightMobile};

  ${media.medium`
    height: ${navHeight};
  `};
`

const Logo = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;
  color: ${white};
  transition: color ${animationTime} ${animationCurve};

  ${media.medium`
    font-size: 20px;
  `};

  ${media.large`
    font-size: 21px;
  `};

  ${media.xLarge`
    font-size: 24px;
  `};

  &:after {
    content: '';
    position: absolute;
    bottom: 5%;
    left: -2px;
    right: -2px;
    height: 45%;
    z-index: -1;
    background-color: #02828a;
  }
`

const List = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

const MobileWrap = styled.div`
  display: flex;
  align-items: center;
`

const Nav = ({ location }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Wrapper>
        <Inner>
          <Logo to="/">{t('title')}</Logo>

          <List className="u-hide-medium-down">
            <NavItem
              label={t('regions.western')}
              subLinks={[
                {
                  label: t('provinces.bc'),
                  link: '/bc/',
                },
                {
                  label: t('provinces.ab'),
                  link: '/alberta/',
                },
                {
                  label: t('provinces.sk'),
                  link: '/saskatchewan/',
                },
                {
                  label: t('provinces.mb'),
                  link: '/manitoba/',
                },
              ]}
            />
            <NavItem
              label={t('regions.central')}
              subLinks={[
                {
                  label: t('provinces.on'),
                  link: '/ontario/',
                },
                {
                  label: t('provinces.qc'),
                  link: '/quebec/',
                },
              ]}
            />
            <NavItem
              label={t('regions.atlantic')}
              subLinks={[
                {
                  label: t('provinces.nb'),
                  link: '/new-brunswick/',
                },
                {
                  label: t('provinces.pei'),
                  link: '/prince-edward-island/',
                },
                {
                  label: t('provinces.ns'),
                  link: '/nova-scotia/',
                },
                {
                  label: t('provinces.nfl'),
                  link: '/newfoundland-and-labrador/',
                },
              ]}
            />
          </List>
          <MobileWrap>
            <LangSwitcher
              location={location}
              destinationLang={currentLang === 'en' ? 'fr' : 'en'}
            />
            <MobileNav />
          </MobileWrap>
        </Inner>
      </Wrapper>
    </Container>
  )
}

export default Nav
