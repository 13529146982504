import React from 'react'
import { node, bool } from 'prop-types'
import styled, { css } from 'styled-components'

import { media } from 'styles/media'
import { verticalPadding } from 'styles/helpers'

const Container = styled.section`
  display: block;
  ${verticalPadding`3rem`};

  ${props =>
    props.flushTop &&
    css`
      padding-top: 0 !important;
    `}

  ${media.small`
    ${verticalPadding`4rem`};
  `};

  ${media.medium`
    ${verticalPadding`5rem`};
  `};

  ${media.large`
    ${verticalPadding`6rem`};
  `};

  ${media.xLarge`
    ${verticalPadding`7.5rem`};
  `};
`

const Block = styled.div`
  position: relative;

  & + & {
    margin-top: 1.75rem;

    ${media.small`
      margin-top: 2rem;
    `};

    ${media.medium`
      margin-top: 2.5rem;
    `};

    ${media.large`
      margin-top: 3rem;
    `};

    ${media.xLarge`
      margin-top: 3.5rem;
    `};
  }
`

const Section = ({ children, flushTop }) => (
  <Container flushTop={flushTop}>{children}</Container>
)

Section.propTypes = {
  children: node.isRequired,
  flushTop: bool,
}

export const SectionBlock = ({ children }) => <Block>{children}</Block>

SectionBlock.propTypes = {
  children: node.isRequired,
}

export default Section
