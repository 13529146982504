import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { bool } from 'prop-types'
import { rgba } from 'polished'
import { Transition } from 'react-transition-group'

import { media } from 'styles/media'
import { boxShadow, square, cover } from 'styles/helpers'
import { greyDark, black, white } from 'styles/colors'
import * as spacing from 'styles/spacing'
import { animationTime, animationCurve, borderRadius } from 'styles/variables'

import Icon from 'components/Icon'
import Portal from 'components/Portal'
import MobileNavItem from './MobileNavItem'

const Container = styled.div`
  overflow: auto;
  transition: all ${animationTime} ${animationCurve};
  z-index: 600;
  opacity: 0;
  visibility: hidden;
  background-color: ${rgba(black, 0.6)};
  overflow-x: hidden;
  ${cover('fixed')};

  ${props =>
    props.animationState === 'entering' ||
    (props.animationState === 'entered' &&
      css`
        opacity: 1;
        visibility: visible;
      `)}

  ${props =>
    props.animationState === 'entered' &&
    css`
      transform: none;
    `}

  ${props =>
    props.animationState === 'exiting' ||
    (props.animationState === 'exited' &&
      css`
        opacity: 0;
        visibility: hidden;
      `)}
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-width: 300px;
  background: ${white};
  transition: transform ${animationTime} ${animationCurve},
    opacity ${animationTime} ${animationCurve};
  background-color: ${white};
  overflow: hidden;
  transform: translateX(15%);
  border-radius: ${borderRadius};
  padding: ${spacing.large};
  opacity: 0;
  ${boxShadow};

  ${media.small`
    max-width: 300px;
  `};

  ${props =>
    props.animationState === 'entered' &&
    css`
      transform: none;
      opacity: 1;
    `}
`

const Toggle = styled.button`
  display: flex;
  padding: ${spacing.small};
  margin: -${spacing.small};
  color: ${white};
  ${square('40px')};

  ${props =>
    props.isDark &&
    css`
      color: ${greyDark};
    `}

  ${media.medium`
    display: none;
  `};
`

const Close = styled.button`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  color: ${greyDark};
  padding: ${spacing.large};
`

const MobileNav = ({ isDark, ...rest }) => {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <Toggle
        onClick={() => {
          setVisible(!visible)
        }}
        isDark={isDark}
      >
        <Icon icon="hamburger" />
      </Toggle>
      <Portal selector="nav-portal">
        <Transition in={visible} timeout={0} unmountOnExit enter>
          {state => (
            <Container animationState={state} {...rest}>
              <Inner animationState={state}>
                <MobileNavItem
                  label="Western Canada"
                  onClick={() => {
                    setVisible(!visible)
                  }}
                  subLinks={[
                    {
                      label: 'British Columbia',
                      link: '/bc/',
                    },
                    {
                      label: 'Alberta',
                      link: '/alberta/',
                    },
                    {
                      label: 'Saskatchewan',
                      link: '/saskatchewan/',
                    },
                    {
                      label: 'Manitoba',
                      link: '/manitoba/',
                    },
                  ]}
                />
                <MobileNavItem
                  label="Central Canada"
                  onClick={() => {
                    setVisible(!visible)
                  }}
                  subLinks={[
                    {
                      label: 'Ontario',
                      link: '/ontario/',
                    },
                    {
                      label: 'Quebec',
                      link: '/quebec/',
                    },
                  ]}
                />
                <MobileNavItem
                  label="Atlantic Canada"
                  onClick={() => {
                    setVisible(!visible)
                  }}
                  subLinks={[
                    {
                      label: 'New Brunswick',
                      link: '/new-brunswick/',
                    },
                    {
                      label: 'Prince Edward Island',
                      link: '/prince-edward-island/',
                    },
                    {
                      label: 'Nova Scotia',
                      link: '/nova-scotia/',
                    },
                    {
                      label: 'Newfoundland and Labrador',
                      link: '/newfoundland-and-labrador/',
                    },
                  ]}
                />
                <Close
                  onClick={() => {
                    setVisible(false)
                  }}
                >
                  <Icon icon="close" />
                </Close>
              </Inner>
            </Container>
          )}
        </Transition>
      </Portal>
    </>
  )
}

MobileNav.propTypes = {
  isDark: bool,
}

export default MobileNav
