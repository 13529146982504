import React from 'react'
import styled, { css } from 'styled-components'
import { string, array, bool } from 'prop-types'

import { white, greyDark, primaryColor } from 'styles/colors'
import { fontWeights, fontSizes } from 'styles/typography'
import { boxShadow } from 'styles/helpers'
import { animationTime, animationCurve, navHeight } from 'styles/variables'
import * as spacing from 'styles/spacing'
import { media } from 'styles/media'
import Link from 'components/LocalizedLink'

const Container = styled.div`
  position: relative;

  & + & {
    margin-left: ${spacing.large};
  }

  &:hover:after {
    content: '';
    position: absolute;
    top: 0;
    left: -${spacing.large};
    right: -${spacing.large};
    bottom: 0;
    display: block;
    cursor: pointer;
  }
`

const Label = styled.button`
  position: relative;
  z-index: 10; /* Position above after element */
  display: inline-flex;
  align-items: center;
  color: ${white};
  font-weight: ${fontWeights.medium};
  cursor: pointer;
  height: ${navHeight};
  transition: color ${animationTime} ${animationCurve},
    box-shadow ${animationTime} ${animationCurve};
  box-shadow: inset 0 -5px 0 0 transparent;

  ${Container}:hover & {
    box-shadow: inset 0 -5px 0 0 ${primaryColor};
  }
`

const Menu = styled.div`
  position: absolute;
  top: ${navHeight};
  left: 0;
  width: 180px;
  background-color: ${white};
  padding: ${spacing.large};
  opacity: 0;
  visibility: hidden;
  transition: all ${animationTime} ${animationCurve};
  z-index: 10;
  margin-top: -${spacing.small};
  ${boxShadow};

  ${media.xLarge`
    width: 210px;
  `};

  ${Container}:hover &,
  ${Container}:focus & {
    opacity: 1;
    visibility: visible;
  }
`

const Item = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: ${fontWeights.medium};
  font-size: ${fontSizes.small};
  transition: color ${animationTime} ${animationCurve};

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `}

  &:hover {
    color: ${greyDark};
  }

  & + & {
    margin-top: ${spacing.small};
  }
`

const NavItem = ({ label, subLinks, isDark }) => (
  <Container>
    <Label isDark={isDark}>{label}</Label>
    {subLinks && (
      <Menu>
        {subLinks.map((subLink, i) => (
          <Item key={i} to={subLink.link} disabled={subLink.disabled}>
            {subLink.label}
          </Item>
        ))}
      </Menu>
    )}
  </Container>
)

NavItem.propTypes = {
  label: string.isRequired,
  subLinks: array,
  isDark: bool,
}

export default NavItem
