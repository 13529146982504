import React from 'react'
import styled from 'styled-components'
import invert from 'lodash/invert'

import { media, mediaDown } from 'styles/media'
import { fontWeights } from 'styles/typography'
import { white } from 'styles/colors'
import * as spacing from 'styles/spacing'

import { domainLangMap, pathLangMap } from 'i18n/urls'

const currentLang = process.env.GATSBY_LOCALE

const Container = styled.a`
  font-weight: ${fontWeights.medium};
  color: ${white};

  ${media.medium`
    margin-left: ${spacing.large};
  `};

  ${mediaDown.medium`
    padding: ${spacing.small};
    margin-right: ${spacing.small};
  `};
`

const LangSwitcher = ({ location, destinationLang }) => {
  const langIndexURL = domainLangMap[currentLang === 'en' ? 'fr' : 'en']
  const translatedPath = (() => {
    if (location.pathname === '/') return '/'
    if (currentLang === 'en')
      return pathLangMap[destinationLang][location.pathname]

    const invertedPathLangMap = invert(pathLangMap[currentLang])
    return invertedPathLangMap[location.pathname]
  })()

  return (
    <Container href={langIndexURL + translatedPath}>
      {currentLang === 'en' ? 'FR' : 'EN'}
    </Container>
  )
}

export default LangSwitcher
