import { createGlobalStyle } from 'styled-components'

import Normalize from './normalize'
import Width from './width'
import Push from './push'
import Utilities from './utilities'
import Typography from './typography'
import { white } from './colors'

export const GlobalStyle = createGlobalStyle`
  ${Normalize};
  ${Width};
  ${Push};
  ${Utilities};
  ${Typography};

  body {
    background-color: ${white};
  }
`
