export default {
  translation: {
    title: 'My Vape Regs',
    regions: {
      western: 'Western Canada',
      central: 'Central Canada',
      atlantic: 'Atlantic Canada',
    },
    provinces: {
      bc: 'British Columbia',
      on: 'Ontario',
      ab: 'Alberta',
      sk: 'Saskatchewan',
      mb: 'Manitoba',
      qc: 'Quebec',
      nb: 'New Brunswick',
      pei: 'Prince Edward Island',
      ns: 'Nova Scotia',
      nfl: 'Newfoundland and Labrador',
    },
    home: {
      announcement: {
        heading: 'Important Notice',
        intro: `
          <0>On August 2nd, 2023, the Government of Quebec published changes to provincial vaping regulations.</0>
          <1>Effective in 90 days, into force on October 31st, the following vaping products will be prohibited for sale in Quebec:</1>
        `,
        description: `
          <0><strong>All vaping products having a flavour or aroma other than tobacco</strong> (including those having a mint or menthol flavour). Unflavoured products are permitted.</0>
          <1>Products that have a <strong>volume of liquid of more than 2 ml, and of more than 30 ml for liquid refill containers.</strong></1>
          <2><strong>Products that have a nicotine concentration higher than 20 ml/mg</strong> (note that this is already restricted under Federal Regulations).</2>
          <3>Vaping products that have a form, appearance or function that may be <strong>attractive to minors</strong>.</3>
        `,
      },
      hero: {
        title: 'Vape regulations',
        subtitle:
          'The vaping regulatory landscape across Canada continues to evolve as governments and regulators consider and announce new laws that govern the vaping category. It can be difficult to keep track of these new developments and understand how new regulations impact the products that are available and where they can be purchased.',
      },
      disclaimer:
        'This website will give you the latest information on regulations that affect vapour products. Across all jurisdictions, your choices are being threatened and increased limitations are being imposed. This website will help you stay informed.',
      position: {
        title: 'Our Position',
      },
      excise: {
        title: 'Federal Vaping Excise',
      },
      provinces: {},
    },
    changes: {
      intro: `Recent changes to {{name}} vaping laws`,
      effective: 'Effective',
      bc: [
        'Effective January 1, 2020: the retail sale of vaping products is subject to a 20% provincial tax. Vapour products include vaping devices, cartridges, parts and accessories for these devices, and vaping substances.',
        'Effective July 20, 2020: Flavoured vaping products - other than tobacco flavours - may only be sold online and in adult-only locations. Tobacco flavoured products can continue to be sold in other retail establishments.',
        'Effective July 20, 2020: Vaping products with a concentration of nicotine greater that 20 mg/mL are banned.',
        'Effective July 20, 2020: Nicotine-free vaping products are banned.',
        'Effective July 20, 2020: Refillable containers of vaping e-liquids must contain less that 30mL of e-liquid, and tanks or cartridges must have a capacity of less than 2mL of e-liquid.',
        'FEDERAL: Effective October 1, 2022, federal excise will begin to apply to vaping substances, increasing their price to consumers, at a rate dependent on the volume of the substance in each container purchased (e.g. in each vaping cartridge, pod or bottle of e-liquid). This excise rate is set at $1 per 2 mL of substance in each container up to 10 mL, plus $1 per 10 mL of liquid above that. By January 1, 2023, all vaping substances sold at retail must carry a federal “DUTY PAID” excise stamp.  The federal excise will not apply to vaping devices, batteries or other accessories.',
      ],
      on: [
        'FEDERAL: Effective October 1, 2022, federal excise will begin to apply to vaping substances, increasing their price to consumers, at a rate dependent on the volume of the substance in each container purchased (e.g. in each vaping cartridge, pod or bottle of e-liquid). This excise rate is set at $1 per 2 mL of substance in each container up to 10 mL, plus $1 per 10 mL of liquid above that. By January 1, 2023, all vaping substances sold at retail must carry a federal “DUTY PAID” excise stamp.  The federal excise will not apply to vaping devices, batteries or other accessories.',
        'Effective July 1, 2020: Flavoured vaping products - other than tobacco, mint and menthol flavours - may only be sold online and in specialty vape stores. Tobacco, mint and menthol flavoured products can continue to be sold in other retail establishments.',
        'FEDERAL: After July 23, 2021, the sale of vaping products with a concentration of nicotine greater that 20 mg/ml will be banned.',
      ],
      pei: [
        'FEDERAL: Effective October 1, 2022, federal excise will begin to apply to vaping substances, increasing their price to consumers, at a rate dependent on the volume of the substance in each container purchased (e.g. in each vaping cartridge, pod or bottle of e-liquid). This excise rate is set at $1 per 2 mL of substance in each container up to 10 mL, plus $1 per 10 mL of liquid above that. By January 1, 2023, all vaping substances sold at retail must carry a federal “DUTY PAID” excise stamp.  The federal excise will not apply to vaping devices, batteries or other accessories.',
        'Effective March 1, 2020: Vaping products may only be sold to people who are 21 or older (except for those who turned 19 prior to March 1, 2020)',
        'Effective March 1, 2020: Vaping products may only be sold in a “tobacconist shop” (a shop whose primary business is the sale of tobacco products, vaping products, or both).',
        'Effective March 1, 2020: Flavoured vaping products other than the tobacco flavour can no longer be sold after March 1, 2021',
        'FEDERAL: After July 23, 2021, the sale of vaping products with a concentration of nicotine greater that 20 mg/ml will be banned.',
      ],
      ns: [
        'Effective April 1, 2020: The sale of flavoured vaping products will be banned, with the exception of tobacco flavours.',
        'Effective September 1, 2020: The sale of vaping products with a concentration of nicotine greater that 20 mg/mL will be banned.',
        'Effective September 15, 2020: Vaping products will be subject to a new provincial tax. For vaping liquids, this tax will be $0.50 per millilitre. For vaping devices and components, the new tax will be 20% of the product’s recommended retail selling price.',
      ],
      ab: [
        'FEDERAL: Effective July 23, 2021, the sale of vaping products with a concentration of nicotine greater that 20 mg/ml is prohibited.',
        'FEDERAL: Effective October 1, 2022, federal excise will begin to apply to vaping substances, increasing their price to consumers, at a rate dependent on the volume of the substance in each container purchased (e.g. in each vaping cartridge, pod or bottle of e-liquid). This excise rate is set at $1 per 2 mL of substance in each container up to 10 mL, plus $1 per 10 mL of liquid above that. By January 1, 2023, all vaping substances sold at retail must carry a federal “DUTY PAID” excise stamp.  The federal excise will not apply to vaping devices, batteries or other accessories.',
      ],
      mb: [
        'FEDERAL: Effective July 23, 2021, the sale of vaping products with a concentration of nicotine greater that 20 mg/ml is prohibited.',
        'FEDERAL: Effective October 1, 2022, federal excise will begin to apply to vaping substances, increasing their price to consumers, at a rate dependent on the volume of the substance in each container purchased (e.g. in each vaping cartridge, pod or bottle of e-liquid). This excise rate is set at $1 per 2 mL of substance in each container up to 10 mL, plus $1 per 10 mL of liquid above that. By January 1, 2023, all vaping substances sold at retail must carry a federal “DUTY PAID” excise stamp.  The federal excise will not apply to vaping devices, batteries or other accessories.',
      ],
      qc: [
        'Effective in 90 days (as of August 2nd, 2023): The Regulation will come into force in Quebec for all parties at the same time. There is no separate retail cut-off period. This mean that products that do not meet the above requirements cannot be sold be retailers after October 30th, 2023. All vaping products having a flavour or aroma other than tobacco (including those having a mint or menthol flavour) will be prohibited from sale. In addition, vaping products that have a volume of liquid of more than 2 ml, or of more than 30 ml for liquid refill containers, and nicotine concentration higher than 20 ml/mg will be prohibited. The sale of vaping products that have a form, appearance or function that may be attractive to minors will also be prohibited.',
        'FEDERAL: Effective July 23, 2021, the sale of vaping products with a concentration of nicotine greater that 20 mg/ml is prohibited.',
        'FEDERAL: Effective October 1, 2022, federal excise will begin to apply to vaping substances, increasing their price to consumers, at a rate dependent on the volume of the substance in each container purchased (e.g. in each vaping cartridge, pod or bottle of e-liquid). This excise rate is set at $1 per 2 mL of substance in each container up to 10 mL, plus $1 per 10 mL of liquid above that. By January 1, 2023, all vaping substances sold at retail must carry a federal “DUTY PAID” excise stamp.  The federal excise will not apply to vaping devices, batteries or other accessories.',
      ],
      sk: [
        'FEDERAL: Effective July 23, 2021, the sale of vaping products with a concentration of nicotine greater that 20 mg/ml is prohibited.',
        'Effective September 1, 2021: Flavoured vaping products - other than tobacco, mint and menthol flavours - may only be sold online and in specialty vape stores. Tobacco, mint and menthol flavoured products can continue to be sold in other retail establishments.',
        'Effective September 1, 2021: a provincial vapour products tax of 20% is applicable on the retail price of all vapour liquids, products and devices.',
        'FEDERAL: Effective October 1, 2022, federal excise will begin to apply to vaping substances, increasing their price to consumers, at a rate dependent on the volume of the substance in each container purchased (e.g. in each vaping cartridge, pod or bottle of e-liquid). This excise rate is set at $1 per 2 mL of substance in each container up to 10 mL, plus $1 per 10 mL of liquid above that. By January 1, 2023, all vaping substances sold at retail must carry a federal “DUTY PAID” excise stamp.  The federal excise will not apply to vaping devices, batteries or other accessories.',
      ],
      nb: [
        'FEDERAL: Effective July 23, 2021, the sale of vaping products with a concentration of nicotine greater that 20 mg/ml is prohibited.',
        'Effective September 1, 2021: The sale of flavoured vaping products is prohibited, with the exception of tobacco flavours.',
        'FEDERAL: Effective October 1, 2022, federal excise will begin to apply to vaping substances, increasing their price to consumers, at a rate dependent on the volume of the substance in each container purchased (e.g. in each vaping cartridge, pod or bottle of e-liquid). This excise rate is set at $1 per 2 mL of substance in each container up to 10 mL, plus $1 per 10 mL of liquid above that. By January 1, 2023, all vaping substances sold at retail must carry a federal “DUTY PAID” excise stamp.  The federal excise will not apply to vaping devices, batteries or other accessories.',
      ],
      nfl: [
        'Effective January 1, 2021, the retail sale of vaping products is subject to a 20% provincial tax. The tax applies to vaping devices, substances and packages containing both vaping devices and substances.',
        'FEDERAL: Effective July 23, 2021, the sale of vaping products with a concentration of nicotine greater that 20 mg/ml is prohibited.',
        'FEDERAL: Effective October 1, 2022, federal excise will begin to apply to vaping substances, increasing their price to consumers, at a rate dependent on the volume of the substance in each container purchased (e.g. in each vaping cartridge, pod or bottle of e-liquid). This excise rate is set at $1 per 2 mL of substance in each container up to 10 mL, plus $1 per 10 mL of liquid above that. By January 1, 2023, all vaping substances sold at retail must carry a federal “DUTY PAID” excise stamp.  The federal excise will not apply to vaping devices, batteries or other accessories.',
      ],
    },
    footer: {
      factsNotFear: {
        text:
          "For more information on the issues surrounding responsible vaping regulation, visit Imperial Tobacco Canada's",
        link: 'https://factsnotfear.ca/',
      },
    },
  },
}
