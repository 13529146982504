export default {
  translation: {
    title: 'Normes de Vapotage',
    regions: {
      western: 'Ouest du Canada',
      central: 'Est du Canada',
      atlantic: 'Canada atlantique',
    },
    provinces: {
      bc: 'Colombie-Britannique',
      on: 'Ontario',
      ab: 'Alberta',
      sk: 'Saskatchewan',
      mb: 'Manitoba',
      qc: 'Québec',
      nb: 'Nouveau Brunswick',
      pei: 'Île-du-Prince-Édouard',
      ns: 'Nouvelle-Écosse',
      nfl: 'Terre-Neuve-et-Labrador',
    },
    home: {
      announcement: {
        heading: 'Avis Important',
        intro: `
          <0>Le 2 août 2023, le gouvernement du Québec a publié des modifications à la réglementation sur le vapotage.</0>
          <1>Le 31 octobre 2023, les produits de vapotage suivants seront interdits au Québec :</1>
        `,
        description: `
          <0><strong>Tous les produits de vapotage ayant une saveur autre que le tabac</strong> (y compris ceux ayant une saveur de menthe ou de menthol). Les produits sans saveur sont autorisés.</0>
          <1>Les produits dont le <strong>volume de liquide est supérieur à 2 ml, et à 30 ml pour les contenants de recharge</strong>.</1>
          <2><strong>Les produits dont la concentration en nicotine est supérieure à 20 ml/mg</strong> (à noter que cette concentration est déjà limitée par le règlement fédéral).</2>
          <3>Les produits de vapotage dont la forme, l'apparence ou la fonction <strong>peuvent attirer les mineurs</strong>.</3>
        `,
      },
      hero: {
        title: 'Règlementation relative au vapotage',
        subtitle:
          'Le contexte réglementaire du vapotage au Canada continue d’évoluer au fur et à mesure que les gouvernements ainsi que les responsables de la réglementation examinent et annoncent de nouvelles lois régissant les produits de vapotage. Il peut être difficile de suivre ces nouveaux développements et de comprendre comment la réglementation influence les produits qui sont offerts ainsi que l’endroit où ils sont vendus.',
      },
      disclaimer:
        'Ce site Web présente les plus récentes informations en matière de réglementation sur les produits de vapotage. D’une compétence à l’autre, votre liberté de choix est menacée et des limites de plus en plus sévères sont imposées. Ce site Web vous permet de demeurer informé.',
      position: {
        title: 'Notre Position',
        description: `
          <0>Imperial Tobacco Canada soutient la réglementation appuyée sur des faits qui vise à équilibrer la nécessité de donner aux fumeurs adultes l’accès à des solutions de rechange viables et moins risquées que les cigarettes tout en empêchant les jeunes de s’en procurer.</0>
          <1>Le gouvernement fédéral a déjà mis en place un cadre réglementaire pour les produits de vapotage qui comprend des limites pour les taux de nicotine, des restrictions sur les saveurs, des restrictions en matière de publicité, des exigences relatives à l’emballage et une interdiction de vente aux mineurs. Ces lois doivent être strictement appliquées et toute personne qui les enfreint doit être sévèrement punie.</1>
          <2>Comme on l’a constaté dans d’autres pays, les produits de vapotage ont le potentiel de jouer un rôle important dans l’atteinte de l’objectif du gouvernement fédéral qui vise à réduire le taux de tabagisme à cinq pour cent d’ici 2035. Toutefois, pour atteindre cet objectif, il faut mettre en place une réglementation appropriée qui permet et maintient la communication avec les fumeurs adultes, qui applique strictement les lois et règlements actuels, qui rend les produits viables pour les fumeurs adultes souhaitant abandonner la cigarette et qui met en œuvre des normes uniformes pour les produits. Une réglementation provinciale excessive et désorganisée ne permettra pas à ces produits d’atteindre l’objectif prévu de réduction des risques.</2>
        `,
      },
      excise: {
        title: 'Accise fédérale sur le vapotage',
        description: `
          <0>À partir du 1er octobre 2022, l'accise fédérale s'appliquera aux substances de vapotage, ce qui augmentera les prix pour les consommateurs, à un taux dépendant du volume de la substance dans chaque contenant acheté (par exemple, dans chaque cartouche de vapotage, capsule ou bouteille d'e-liquide).</0>
          <1>Les droits d'accises sont indiqués dans le tableau ci-dessous :</1>
        `,
      },
      provinces: {
        title: `<0>Cliquez sur les provinces</0> de la carte ci-dessous pour consulter les nouvelles réglementations sur le vapotage.`,
      },
    },
    changes: {
      intro:
        'Récentes modifications apportées aux lois sur le vapotage pour {{name}}',
      effective: 'Entrée en vigueur le',
      bc: [
        'À compter du 1er janvier 2020 : la vente au détail de produits de vapotage est assujettie à une taxe provinciale de 20 %. Les produits de vapotage comprennent les appareils de vapotage, les cartouches, les pièces et accessoires de ces appareils et les substances de vapotage.',
        'Entrée en vigueur le 20 juillet 2020 : Les produits de vapotage aromatisés, autres que les saveurs de tabac, peuvent uniquement être vendus en ligne et dans des magasins uniquement accessibles aux adultes. Les produits aromatisés au tabac peuvent continuer d’être vendus dans des établissements de vente au détail.',
        'Entrée en vigueur le 20 juillet 2020 : Les produits de vapotage qui ont une teneur en nicotine supérieure à 20 mg/ml sont interdits.',
        'Entrée en vigueur le 20 juillet 2020 : Les produits de vapotage sans nicotine sont interdits.',
        'Entrée en vigueur le 20 juillet 2020 : Les récipients réutilisables de liquide de vapotage doivent contenir moins de 30 ml de liquide et les réservoirs ou cartouches doivent avoir une capacité de liquide inférieure à 2 ml.',
        'AU PLAN FÉDÉRAL : À compter du 1er octobre 2022, la taxe d’accise fédérale commencera à s’appliquer aux substances de vapotage, augmentant leur prix pour les consommateurs, à un taux dépendant du volume de la substance dans chaque contenant acheté (par exemple, dans chaque cartouche de vapotage, dosette ou bouteille de liquide).  Ce taux d’accise est fixé à 1 $ par 2 ml de substance dans chaque contenant jusqu’à 10 ml, plus 1 $ par 10 ml de liquide au-delà.  D’ici le 1er janvier 2023, toutes les substances de vapotage vendues au détail doivent porter un timbre d’accise fédérale avec mention : dédouané « DUTY PAID ».  L’accise fédérale ne s’appliquera pas aux appareils de vapotage, aux batteries ou à d’autres accessoires.',
      ],
      on: [
        'À compter du 1er juillet 2020 : Les produits de vapotage aromatisés, autres que les saveurs de tabac, de menthe et de menthol, peuvent uniquement être vendus en ligne et dans les boutiques de vapotage spécialisées. Les produits à saveur de tabac, de menthe et de menthol peuvent continuer d’être vendus dans des établissements de vente au détail.',
        'AU PLAN FÉDÉRAL : À compter du 23 juillet 2021, la vente de produits de vapotage dont le taux de nicotine est supérieur à 20 mg/ml est interdite.',
        'AU PLAN FÉDÉRAL : À compter du 1er octobre 2022, la taxe d’accise fédérale commencera à s’appliquer aux substances de vapotage, augmentant leur prix pour les consommateurs, à un taux dépendant du volume de la substance dans chaque contenant acheté (par exemple, dans chaque cartouche de vapotage, dosette ou bouteille de liquide).  Ce taux d’accise est fixé à 1 $ par 2 ml de substance dans chaque contenant jusqu’à 10 ml, plus 1 $ par 10 ml de liquide au-delà.  D’ici le 1er janvier 2023, toutes les substances de vapotage vendues au détail doivent porter un timbre d’accise fédéral dédouané « DUTY PAID ».  L’accise fédérale ne s’appliquera pas aux appareils de vapotage, aux batteries ou à d’autres accessoires.',
      ],
      pei: [
        'À compter du 1er mars 2020 : les produits de vapotage peuvent uniquement être vendus aux personnes âgées de 21 ans et plus (à l’exception de celles qui ont eu 19 ans avant le 1er mars 2020).',
        'Entrée en vigueur le 1 mars 2020 : Les produits de vapotage peuvent uniquement être vendus dans des tabagies (magasins dont l’activité principale consiste à vendre des produits du tabac, des produits de vapotage ou les deux).',
        'À compter du 1er mars 2020 : Les produits de vapotage aromatisés autres qu’à saveur de tabac ne pourront plus être vendus après le 1er mars 2021.',
        'AU PLAN FÉDÉRAL : À compter du 23 juillet 2021, la vente de produits de vapotage dont le taux de nicotine est supérieur à 20 mg/ml est interdite.',
        'AU PLAN FÉDÉRAL : À compter du 1er octobre 2022, la taxe d’accise fédérale commencera à s’appliquer aux substances de vapotage, augmentant leur prix pour les consommateurs, à un taux dépendant du volume de la substance dans chaque contenant acheté (par exemple, dans chaque cartouche de vapotage, dosette ou bouteille de liquide).  Ce taux d’accise est fixé à 1 $ par 2 ml de substance dans chaque contenant jusqu’à 10 ml, plus 1 $ par 10 ml de liquide au-delà.  D’ici le 1er janvier 2023, toutes les substances de vapotage vendues au détail doivent porter un timbre d’accise fédéral dédouané « DUTY PAID ».  L’accise fédérale ne s’appliquera pas aux appareils de vapotage, aux batteries ou à d’autres accessoires.',
      ],
      ns: [
        'À compter du 1er avril 2020 : la vente de produits de vapotage aromatisés est interdite, à l’exception de ceux à saveur de tabac.',
        'À compter du 1er septembre 2020 : la vente de produits de vapotage ayant un taux de nicotine supérieur à 20 mg/ml est interdite.',
        'Entrée en vigueur le 15 septembre 2020 : Les produits de vapotage seront assujettis à une taxe provinciale. Pour les liquides de vapotage, cette taxe est de 0,50 $ par ml. Pour les dispositifs de vapotage et leurs composants, la taxe est de 20 % du prix de vente au détail recommandé pour le produit.',
        'AU PLAN FÉDÉRAL : À compter du 1er octobre 2022, la taxe d’accise fédérale commencera à s’appliquer aux substances de vapotage, augmentant leur prix pour les consommateurs, à un taux dépendant du volume de la substance dans chaque contenant acheté (par exemple, dans chaque cartouche de vapotage, dosette ou bouteille de liquide).  Ce taux d’accise est fixé à 1 $ par 2 ml de substance dans chaque contenant jusqu’à 10 ml, plus 1 $ par 10 ml de liquide au-delà.  D’ici le 1er janvier 2023, toutes les substances de vapotage vendues au détail doivent porter un timbre d’accise fédéral dédouané « DUTY PAID ».  L’accise fédérale ne s’appliquera pas aux appareils de vapotage, aux batteries ou à d’autres accessoires.',
      ],
      ab: [
        'AU PLAN FÉDÉRAL : Date d’entrée en vigueur 23 juillet 2021, la vente de produits de vapotage dont le taux de nicotine est supérieur à 20 mg/ml est interdite.',
        'AU PLAN FÉDÉRAL : À compter du 1er octobre 2022, la taxe d’accise fédérale commencera à s’appliquer aux substances de vapotage, augmentant leur prix pour les consommateurs, à un taux dépendant du volume de la substance dans chaque contenant acheté (par exemple, dans chaque cartouche de vapotage, dosette ou bouteille de liquide).  Ce taux d’accise est fixé à 1 $ par 2 ml de substance dans chaque contenant jusqu’à 10 ml, plus 1 $ par 10 ml de liquide au-delà.  D’ici le 1er janvier 2023, toutes les substances de vapotage vendues au détail doivent porter un timbre d’accise fédéral dédouané « DUTY PAID ».  L’accise fédérale ne s’appliquera pas aux appareils de vapotage, aux batteries ou à d’autres accessoires.',
      ],
      mb: [
        'AU PLAN FÉDÉRAL : À compter du 23 juillet 2021, la vente de produits de vapotage dont le taux de nicotine est supérieur à 20 mg/ml est interdite.',
        'AU PLAN FÉDÉRAL : À compter du 1er octobre 2022, la taxe d’accise fédérale commencera à s’appliquer aux substances de vapotage, augmentant leur prix pour les consommateurs, à un taux dépendant du volume de la substance dans chaque contenant acheté (par exemple, dans chaque cartouche de vapotage, dosette ou bouteille de liquide).  Ce taux d’accise est fixé à 1 $ par 2 ml de substance dans chaque contenant jusqu’à 10 ml, plus 1 $ par 10 ml de liquide au-delà.  D’ici le 1er janvier 2023, toutes les substances de vapotage vendues au détail doivent porter un timbre d’accise fédéral dédouané « DUTY PAID ».  L’accise fédérale ne s’appliquera pas aux appareils de vapotage, aux batteries ou à d’autres accessoires.',
      ],
      qc: [
        "En vigueur dans 90 jours (à partir du 2 août 2023) : Le règlement entrera en vigueur au Québec pour tous en même temps. Il n'y a pas d’exemption pour la vente au détail. Cela signifie que les produits qui ne satisfont pas aux exigences susmentionnées ne peuvent être vendus aux détaillants après le 30 octobre 2023. Tous les produits de vapotage ayant une saveur autre que le tabac (y compris ceux ayant une saveur de menthe ou de menthol) seront interdites à la vente. En outre, les produits de vapotage dont le volume de liquide est supérieur à 2 ml, ou à 30 ml pour les contenants de recharge, et dont la concentration en nicotine est supérieure à 20 ml/mg seront interdits. La vente de produits de vapotage dont la forme, l'apparence ou la fonction peuvent attirer les mineurs ne peuvent être vendus.",
        'AU PLAN FÉDÉRAL : À compter du 23 juillet 2021, la vente de produits de vapotage dont le taux de nicotine est supérieur à 20 mg/ml est interdite.',
        'AU PLAN FÉDÉRAL : À compter du 1er octobre 2022, la taxe d’accise fédérale commencera à s’appliquer aux substances de vapotage, augmentant leur prix pour les consommateurs, à un taux dépendant du volume de la substance dans chaque contenant acheté (par exemple, dans chaque cartouche de vapotage, dosette ou bouteille de liquide).  Ce taux d’accise est fixé à 1 $ par 2 ml de substance dans chaque contenant jusqu’à 10 ml, plus 1 $ par 10 ml de liquide au-delà.  D’ici le 1er janvier 2023, toutes les substances de vapotage vendues au détail doivent porter un timbre d’accise fédéral dédouané « DUTY PAID ».  L’accise fédérale ne s’appliquera pas aux appareils de vapotage, aux batteries ou à d’autres accessoires.',
      ],
      sk: [
        'AU PLAN FÉDÉRAL : À compter du 23 juillet 2021, la vente de produits de vapotage dont le taux de nicotine est supérieur à 20 mg/ml est interdite.',
        'À compter du 1er septembre 2021 : les produits de vapotage aromatisés, autres que les saveurs de tabac, de menthe et de menthol, peuvent uniquement être vendus en ligne et dans les boutiques de vapotage spécialisées. Les produits à saveur de tabac, de menthe et de menthol peuvent continuer d’être vendus dans des établissements de vente au détail.',
        'À compter du 1er septembre 2021 : une taxe provinciale sur les produits de vapotage de 20 % est applicable sur le prix de détail de tous les liquides, produits et appareils de vapotage.',
        'AU PLAN FÉDÉRAL : À compter du 1er octobre 2022, la taxe d’accise fédérale commencera à s’appliquer aux substances de vapotage, augmentant leur prix pour les consommateurs, à un taux dépendant du volume de la substance dans chaque contenant acheté (par exemple, dans chaque cartouche de vapotage, dosette ou bouteille de liquide).  Ce taux d’accise est fixé à 1 $ par 2 ml de substance dans chaque contenant jusqu’à 10 ml, plus 1 $ par 10 ml de liquide au-delà.  D’ici le 1er janvier 2023, toutes les substances de vapotage vendues au détail doivent porter un timbre d’accise fédérale avec mention : dédouané « DUTY PAID ».  L’accise fédérale ne s’appliquera pas aux appareils de vapotage, aux batteries ou à d’autres accessoires.',
      ],
      nb: [
        'AU PLAN FÉDÉRAL : À compter du 23 juillet 2021, la vente de produits de vapotage dont le taux de nicotine est supérieur à 20 mg/ml est interdite.',
        'À compter du 1er septembre 2021 : La vente de produits de vapotage aromatisés est interdite, à l’exception de ceux à saveur de tabac.',
        'AU PLAN FÉDÉRAL : À compter du 1er octobre 2022, la taxe d’accise fédérale commencera à s’appliquer aux substances de vapotage, augmentant leur prix pour les consommateurs, à un taux dépendant du volume de la substance dans chaque contenant acheté (par exemple, dans chaque cartouche de vapotage, dosette ou bouteille de liquide).  Ce taux d’accise est fixé à 1 $ par 2 ml de substance dans chaque contenant jusqu’à 10 ml, plus 1 $ par 10 ml de liquide au-delà.  D’ici le 1er janvier 2023, toutes les substances de vapotage vendues au détail doivent porter un timbre d’accise fédérale avec mention : dédouané « DUTY PAID ».  L’accise fédérale ne s’appliquera pas aux appareils de vapotage, aux batteries ou à d’autres accessoires.',
      ],
      nfl: [
        'À compter du 1er janvier 2020, la vente au détail de produits de vapotage est assujettie à une taxe provinciale de 20 %. La taxe s’applique aux appareils de vapotage, aux substances et aux emballages contenant à la fois des appareils et des substances de vapotage.',
        'AU PLAN FÉDÉRAL : À compter du 23 juillet 2021, la vente de produits de vapotage dont le taux de nicotine est supérieur à 20 mg/ml est interdite.',
        'AU PLAN FÉDÉRAL : À compter du 1er octobre 2022, la taxe d’accise fédérale commencera à s’appliquer aux substances de vapotage, augmentant leur prix pour les consommateurs, à un taux dépendant du volume de la substance dans chaque contenant acheté (par exemple, dans chaque cartouche de vapotage, dosette ou bouteille de liquide).  Ce taux d’accise est fixé à 1 $ par 2 ml de substance dans chaque contenant jusqu’à 10 ml, plus 1 $ par 10 ml de liquide au-delà.  D’ici le 1er janvier 2023, toutes les substances de vapotage vendues au détail doivent porter un timbre d’accise fédérale avec mention : dédouané « DUTY PAID ».  L’accise fédérale ne s’appliquera pas aux appareils de vapotage, aux batteries ou à d’autres accessoires.',
      ],
    },
    footer: {
      factsNotFear: {
        text:
          'Pour obtenir plus de renseignements sur les enjeux entourant la réglementation relative au vapotage responsable, visitez le site d’Imperial Tobacco Canada',
        link: 'https://desfaitssanspeur.ca/',
      },
      vuse: `
        <0>Pour de plus amples renseignements sur les produits de vapotage d’Imperial Tobacco Canada, veuillez </0>
        <1>cliquer ici</1>
      `,
    },
  },
}
