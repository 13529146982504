// Primary

export const teal = '#2d5e6d'
export const green = '#45D8A7'

// Greys

export const greyDark = '#222'
export const grey = '#444'
export const greyLight = '#999'
export const greyLightest = '#ddd'
export const offWhite = '#f4f4f4'

// Misc

export const white = '#fff'
export const black = '#000'

// Set colors

export const primaryColor = teal
export const backgroundColor = offWhite
export const borderColor = greyLightest
